<template>
  <v-container fluid>
    <v-toolbar
      class="my-2"
      color="transparent"
      :dark="this.$store.state.darkTheme ? true : false"
      
    >
      <v-toolbar-title>
        <h1 class="font-weight-light">
          Project to review
        </h1>
      </v-toolbar-title>
    </v-toolbar>

    <v-alert
      text
      :color="darkTheme ? 'primary':blue"
      :dark="darkTheme ? true:false"
      class="title font-weight-light"
    >
      <p>
        Hello reviewer !
        <br>Please find projects to review on this page.
      </p>
      You have {{ this.$store.state.projectsToReview.projects.length }} project(s) to review.
      <div
        v-for="(project,index) in this.$store.state.projectsToReview.projects"
        :key="index"
      >
        > {{ project.name }}
      </div>
    </v-alert>

    <v-divider
      class="my-4"
      :dark="darkTheme?true:false"
    />

    <v-row
      v-if="this.$store.state.projectsToReview.projects.length == 0"
      class="my-6 mx-6"
      style="color:grey"
    >
      ... there is no project to review for now ...
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title>
          Server answer
          <v-spacer />
          <v-btn
            color="primary"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ answerServerDescription }}
          <br>
          {{ answerServerScientificDescription }}
          <br>
          {{ answerServerTechnicalDescription }}
          <br>
          {{ answerServerPerseusData }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <div
      v-for="(project,index) in this.$store.state.projectsToReview.projects"
      :key="index"
    >
      <v-card
        :color="darkTheme ? card_bg_dark:card_bg_light"
        :dark="darkTheme ? true:false"
        class="my-6 mx-4"
      >
        <v-toolbar color="primary">
          <v-toolbar-title>{{ project.name }} ( Admin: {{ project.admins[0].uid }} )</v-toolbar-title>
          <v-btn
            outlined
            color="white"
            absolute
            right
            :to="`/my-projects/project/${project.name}`"
          >
            Go to project page
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-alert
          v-if="project.acceptBool"
          text
          :color="darkTheme ? 'primary':blue"
          :dark="darkTheme ? true:false"
          type="success"
          prominent
        >
          This project has been reviewed. Please go to project List and activate it.
          <br>
          <v-btn
            to="/project-list"
            absolute
            bottom
            right
            color="primary"
          >
            <v-icon>
              fa fa-server
            </v-icon> &ensp;
            Project List
          </v-btn>
        </v-alert>

        <v-card
          class="my-4 mx-4"
          :color="darkTheme ? card_bg_dark:card_bg_light"
        >
          <v-card-title>
            About the project
            <v-btn
              absolute
              right
              top
              :color="red"
              @click="(updateDescription(index))"
            >
              <v-icon left>
                fas fa-save
              </v-icon>
              Save
            </v-btn>
          </v-card-title>
          <v-col>
            <v-text-field
              v-model="project.description"
              :dark="$store.state.darkTheme?true:false"
              label="Description"
              :rules="[rules.title, rules.friendlyTitle]"
              outlined
              hint="Title of your project"
            />
          </v-col>
        </v-card>

        <v-card
          class="my-4 mx-4"
          :color="darkTheme ? card_bg_dark:card_bg_light"
        >
          <v-card-title>Review for &thinsp; <div v-for="(admin,index) in project.reviewers" :key="index"> {{ admin }} &thinsp;</div>
       </v-card-title>

          <!-- Technical review part -->
          <v-row
            class="mx-4 align-middle justify-left"
            dense
          >
            <v-col
              cols="12"
              class="d-inline-flex"
            >
              <v-checkbox
                v-model="project.technicalReview"
                :color="blue"
              />
              <v-card-subtitle>Technical Review</v-card-subtitle>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="project.technicalDescription"
                :color="blue"
                label="Technical description"
                name="text-area-technical"
                outlined
              />
            </v-col>
          </v-row>

          <!-- Scientific review part -->
          <v-row
            class="mx-4 align-middle"
            dense
          >
            <v-col
              cols="12"
              class="d-inline-flex"
            >
              <v-checkbox
                v-model="project.scientificReview"
                :color="blue"
              />
              <v-card-subtitle>Scientific Review</v-card-subtitle>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="project.scientificDescription"
                :color="blue"
                label="Scientific description"
                name="text-area-scientific"
                outlined
              />
            </v-col>
          </v-row>

          <!-- Reject reason -->
          <v-row align="center">
            <v-col>
              <v-text-field
                v-model="rejectReason[index]"
                class="mx-4"
                label="Reject Reason"
                required
                :color="red"
                :disabled="(reject[index])"
              />
            </v-col>
            <v-col
              align-self="end"
              class="my-4"
              cols="2"
            >
              <v-btn
                outlined
                :color="red"
                :disabled="(!rejectReason[index] || reject[index])"
                @click.native="(rejectFunction(index))"
              >
                Reject
              </v-btn>
            </v-col>
            <v-col>
              <h3> Think to click on reject and save to valid the rejection</h3>
            </v-col>
          </v-row>
        </v-card>
        <v-row />
      </v-card>

      <v-divider
        class="my-4"
        :dark="darkTheme?true:false"
      />
    </div>

    <br>
  </v-container>
</template>

<script>
  import config from '@/config'
  import store from '../store'
  import axios from 'axios'
  import checkToken from '../store/checkToken'
  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,
      commentwaiting: [],
      reject: [],
      rejectReason: [],
      dialog: false,
      answerServerDescription: '',
      answerServerScientificDescription: '',
      answerServerTechnicalDescription: '',
      answerServerPerseusData: '',
      rules: {
        title: v => !!v || 'You must choose a title for your project',
        friendlyTitle: v => !(v || '').match(/[^a-z0-9A-Z ]/) || "Please, don't use accent or special characteres",
      },
    }),
    computed: {
      darkTheme () {
        return this.$store.state.darkTheme
      },
    },
    methods: {
      closeDialog () {
        this.dialog = false
        this.answerServerDescription = ''
        this.answerServerScientificDescription = ''
        this.answerServerTechnicalDescription = ''
        this.answerServerPerseusData = ''
      },
      pushComment (index, localComment) {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          if (localComment != null) {
            var localDate = new Date()
            const year = localDate.getFullYear()
            const date = localDate.getDate()
            const month = localDate.getMonth()
            const hour = localDate.getHours()
            const minutes = localDate.getMinutes()
            const seconde = localDate.getSeconds()
            localDate = year + '-' + month + '-' + date + ' ' + hour + ':' + minutes + ':' + seconde
            var comment = {
              username: this.$store.state.account.login,
              date: localDate,
              comment: localComment,
            }
            this.$store.state.projectsToReview.projects[index].comments.push({
              username: this.$store.state.account.login,
              date: localDate,
              comment: localComment,
            })
            axios
              .get('/project/' + this.$store.state.projectsToReview.projects[index].name + '/get-perseusData')
              .then(response => {
                var perseusData = response.data
                if (perseusData == null) {
                  perseusData = {}
                }
                var previousComments = []
                if (perseusData.comments != null) {
                  previousComments = perseusData.comments
                }
                comment.private = 'NO'
                comment.comment = btoa(localComment)
                if (previousComments.length != null) {
                  previousComments.push(comment)
                } else {
                  previousComments = comment[0]
                }
                perseusData.comments = previousComments
                axios.post('/project/' + this.$store.state.projectsToReview.projects[index].name + '/set-perseusData', JSON.stringify(perseusData))
              })
            this.commentwaiting[index] = ''
            this.$store.state.projectsToReview.projects[index].commentwaiting = ''
          }
        }
      },
      updateDescription (index) {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          axios
            .post('/project/' + this.$store.state.projectsToReview.projects[index].name + '/set-technicalDescription', {
              technicalDescription: this.$store.state.projectsToReview.projects[index].technicalDescription,
            }).then(response => {
              this.answerServerTechnicalDescription = response.data
            })
          axios
            .post('/project/' + this.$store.state.projectsToReview.projects[index].name + '/set-scientificDescription', {
              scientificDescription: this.$store.state.projectsToReview.projects[index].scientificDescription,
            }).then(response => {
              this.answerServerScientificDescription = response.data
            })
          axios
            .post('/project/' + this.$store.state.projectsToReview.projects[index].name + '/set-description', {
              description: this.$store.state.projectsToReview.projects[index].description,
            }).then(response => {
              this.answerServerDescription = response.data
            })
          axios
            .get('/project/' + this.$store.state.projectsToReview.projects[index].name + '/get-perseusData')
            .then(response => {
              var perseusData = response.data
              var scientificReview = null
              var technicalReview = null
              if (perseusData != null) {
                scientificReview = perseusData.scientific_review
                technicalReview = perseusData.technical_review
              } else {
                perseusData = {}
              }
              if (this.$store.state.projectsToReview.projects[index].scientificReview) {
                perseusData.scientific_review = 'true'
              } else {
                perseusData.scientific_review = 'false'
              }
              if (this.$store.state.projectsToReview.projects[index].technicalReview) {
                perseusData.technical_review = 'true'
              } else {
                perseusData.technical_review = 'false'
              }
              if (this.reject[index]) {
                var localDate = new Date()
                const year = localDate.getFullYear()
                const date = localDate.getDate()
                const month = localDate.getMonth()
                const hour = localDate.getHours()
                const minutes = localDate.getMinutes()
                const seconde = localDate.getSeconds()
                localDate = year + '-' + month + '-' + date + ' ' + hour + ':' + minutes + ':' + seconde
                perseusData.reject = {
                  motif: btoa(this.$store.state.projectsToReview.projects[index].rejectReason),
                  username: this.$store.state.account.login,
                  date: localDate,
                }
              }
              if (perseusData.scientific_review === 'true' && perseusData.technical_review === 'true') {
                this.$store.state.projectsToReview.projects[index].acceptBool = true
              } else {
                this.$store.state.projectsToReview.projects[index].acceptBool = false
              }
              axios.post('/project/' + this.$store.state.projectsToReview.projects[index].name + '/set-perseusData', JSON.stringify(perseusData))
                .then(response => {
                  this.answerServerPerseusData = response.data
                  this.dialog = true
                })
            })
        }
      },
      rejectFunction (index) {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          if (this.rejectReason[index] != null) {
            this.reject[index] = true
            this.$store.state.projectsToReview.projects[index].rejectReason = this.rejectReason[index]
            this.rejectReason[index] = ''
            this.$store.state.projectsToReview.projects[index].reject = true
            this.rejectReason[index] = this.$store.state.projectsToReview.projects[index].rejectReason
          }
        }
      },
    },
    beforeRouteEnter (to, from, next) {
      var admin = store.state.admin
      var reviewer = store.state.reviewer
      if (!(admin || reviewer)) {
        next('/')
      } else {
        next()
      }
    },
  }
</script>

<style lang="scss">
</style>
